const timespanOffset = 21600

export let gmdssRequest;
export function makeGmdssRequest() {
    // Copied from gmdssGraphics.js - we need a version of this function that doesn't indirectly import map.js
    if (gmdssRequest) {
        return gmdssRequest;
    }
    gmdssRequest = $j.ajax({
        type: 'get',
        url: '/atlas/gmdss-graphics',
        dataType: 'json'
    }).done(function (response) {
        console.log('GMDSS data arrived');
        return response;
    }).fail(response => {
        if (response.status == 403 && response.responseText == 'Payment required') {
            return [];
        }
    })
    return gmdssRequest;
}

export async function locationGmdssWarnings(point, gmdssData=null) {
    const turfPoint = window.turf.point([point.lon, point.lat]);
    let warnings = {};
    if (!gmdssData) {
        gmdssData = await makeGmdssRequest();
    }
    if (gmdssData) {
        Object.entries(gmdssData).forEach(([warningType, data]) => {
            data.forEach(warning => {
                let displayWarningType = warningType.split('_')[0];
                if (warning.info.length > 0) {
                    let info = warning.info.length > 1 ? warning.info.sort((a, b) => a.time - b.time) : warning.info;
                    if (info[0].type == 'polygon') {
                        let coordinates = info[0].coordinates;
                        coordinates.push(coordinates[0]); // The first and last coordinates must be the same for a turf polygon
                        let geoJSON = window.turf.polygon([coordinates]);
                        if (window.turf.inside(turfPoint, geoJSON)) {
                            // provide a timestamp range for each warning (+/- 3h)
                            // warnings = {warningType: (minTimestamp, maxTimestamp)}
                            let parsedType = displayWarningType.charAt(0).toUpperCase() + displayWarningType.slice(1); //Capitalise first letter
                            if (parsedType in warnings) {
                                let min = info[0].time - timespanOffset,
                                    max = info[0].time + timespanOffset;
                                if (min < warnings[parsedType][0]) {
                                    warnings[parsedType][0] = min;
                                }
                                if (max > warnings[parsedType][1]) {
                                    warnings[parsedType][1] = max;
                                }
                            } else {
                                warnings[parsedType] = [info[0].time-timespanOffset, info[0].time+timespanOffset];
                            }
                        }
                    }
                }
            });
        });
    }
    return warnings;
}
